<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">


        <router-link class="goBack" to="/task1">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </router-link>

        <h1>
          <span>Беседа с заявителем.<br>Выявление желаемой профессии</span>          
        </h1>

        <p>Бердашкевич Максим Сергеевич</p>

        <div class="form-wrap">
          <v-select dense outlined label="Инженер-программист"></v-select>
        </div>


       <p>Выберете выявленные гражданином компетенции</p>

       <div class="skills-list">
          <div class="left">
            <h2>Справочник компетенций</h2>
            <div>
              <v-checkbox label="Уверенный пользователь ПК"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
              <v-checkbox label="Название компетенции"></v-checkbox>
            </div>
          </div>
          <div class="right">
            <h2>Компетенции гражданина</h2>
            <div>
              <v-chip
                class="ma-2"
                color="#F3F6FD"
                close
              >
                Уверенный пользователь ПК
              </v-chip>
            </div>
          </div>
       </div>

        <router-link to="/task3"><v-btn color="blue" style="width: 360px">ДАЛЕЕ</v-btn></router-link>
         



      </div>
    </transition>
  </div>
</template>

<script>


export default {
  name: 'Directories',

  data() {
    return {

    };
  
	}
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style scoped lang="scss">
</style>
